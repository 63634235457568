var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentFactoryResolver, Type, ComponentFactory } from '@angular/core';
var EntryComponentFactoryResolver = /** @class */ (function (_super) {
    __extends(EntryComponentFactoryResolver, _super);
    function EntryComponentFactoryResolver(rootResolver) {
        var _this = _super.call(this) || this;
        _this.rootResolver = rootResolver;
        _this.inCall = false;
        _this.resolvers = new Map();
        _this.resolveComponentFactory = function (component) {
            // Prevents cyclic calls.
            if (_this.inCall) {
                return null;
            }
            _this.inCall = true;
            try {
                var result = _this.resolveInternal(component);
                return result;
            }
            finally {
                _this.inCall = false;
            }
        };
        _this.resolveInternal = function (component) {
            for (var _i = 0, _a = Array.from(_this.resolvers.entries()); _i < _a.length; _i++) {
                var _b = _a[_i], resolver = _b[0], fn = _b[1];
                try {
                    var factory = fn.call(resolver, component);
                    if (factory) {
                        return factory;
                    }
                }
                catch (_c) { }
            }
            return _this.rootResolve.call(_this.rootResolver, component);
        };
        return _this;
    }
    EntryComponentFactoryResolver.prototype.init = function () {
        this.rootResolve = this.rootResolver.resolveComponentFactory;
        this.rootResolver.resolveComponentFactory = this.resolveComponentFactory;
    };
    EntryComponentFactoryResolver.prototype.registerResolver = function (resolver) {
        var original = resolver.resolveComponentFactory;
        this.resolvers.set(resolver, original);
    };
    return EntryComponentFactoryResolver;
}(ComponentFactoryResolver));
export { EntryComponentFactoryResolver };
