<div class="app" [ngClass]="
    {
        'is-collapsed': app.layout.isMenuCollapsed,
        'is-opened': app.layout.isMenuOpened,
        'rtl' : app.layout.rtlActived
    }">
  <div class="layout" [ngClass]="headerSelected">

    <div [ngClass]="sidenavSelected">
      <!-- Side Nav START -->
      <div class="side-nav" sideBar>
        <div class="pointer" [ngClass]="[subDomain == 'swiffi' ? 'side-nav-inner-swiffi' : 'side-nav-inner',
         subDomain == 'gorillas' ? 'side-nav-inner-gorillas' : 'side-nav-inner']">
          <div class="side-nav-logo">
            <a id="home" class="home" (click)="goToMain()">
              <!--<img [hidden]="eddress.getCompanyLogo()" class="logo" src='assets/images/logo/logo.svg'>-->
              <!--<img [hidden]="!eddress.getCompanyLogo()" class="logo" [src]='eddress.getCompanyLogo()'>-->
              <div [hidden]="eddress.getCompanyLogo()" class="logo logo-dark"
                   style="background-image: url('assets/images/logo/logo.svg')"></div>
              <div [hidden]="!eddress.getCompanyLogo()" class="logo logo-dark"
                   [ngStyle]="{'background-image': 'url(' + eddress.getCompanyLogo() + ')'}"></div>
            </a>
            <div class="mobile-toggle side-nav-toggle">
              <a href="javascript:void(0);" (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
          <ul class="side-nav-menu scrollable">

            <li clas="nav-item">
              <div class="mrg-top-30"></div>
            </li>
            <!-- <li class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                  <span class="icon-holder">
                      <i class="ti-package"></i>
                  </span>
                <span class="title">Orders</span>
                <span class="arrow">
                      <i class="ti-angle-right"></i>
                  </span>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item dropdown">
                  <a href="javascript:void(0);">
                    <span>Deliveries</span>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a href="javascript:void(0);">
                    <span>Pickups</span>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a href="javascript:void(0);">
                    <span>Returns</span>
                  </a>
                </li>

              </ul>

            </li>
            <li clas="nav-item" routerLinkActive="active">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder">
                    <i class="ti-user"></i>
                </span>
                <span class="title">Customers</span>
              </a>
            </li> -->
            <!-- <a href="http://localhost:4200/#/dashboard/assign-orders-overview"> -->
            <!-- <a href="http://localhost:4200/#/dashboard/agent-assigned-orders"> -->
            <!-- <li clas="nav-item" routerLinkActive="active">
              <a href="" [routerLink]="['/dashboard']">
            <span class="icon-holder">
                <i class="ti-bar-chart-alt"></i>
            </span>
                <span class="title">Reports</span>
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder">
              <i class="ti-settings"></i>
          </span>
                <span class="title">Configuration</span>
                <span class="arrow">
              <i class="ti-angle-right"></i>
          </span>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item dropdown">
                  <a href="javascript:void(0);">
                    <span>Teams</span>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a href="javascript:void(0);">
                    <span>Zones</span>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a href="javascript:void(0);">
                    <span>Pricing</span>
                  </a>
                </li>

              </ul>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a target="_blank" href="https://staging.eddress.co/portal#!/dashboard/orders-tasks-view/">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Portal</span>
              </a>
            </li>-->

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.MERCHANT]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_WAREHOUSE_DASHBOARD_URL]">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Dashboard</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.CLIENT_EXPRESS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.CLIENT_EXPRESS_VIEW_BRANCHES_URL]">
                <span class="icon-holder"><i class="ti-package"></i></span>
                <span class="title">Branches</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.DISPATCHER, eddress.userRolesEnum.CLIENT_EXPRESS_VIEW]">
              <a href="" [routerLink]="[eddress.appURLEnum.DISPATCHER_TEAMS_URL]">
                <span class="icon-holder"><i class="ti-package"></i></span>
                <span class="title">Dispatcher</span>
              </a>
            </li>

            <li class="nav-item dropdown" [hidden]="!showLastMileSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
                                    <i class="ei-truck"></i>
                                </span>
                <span class="title">Last Mile</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active">
                  <a *ngxPermissionsOnly="[eddress.userRolesEnum.LAST_MILE]"
                     [routerLink]="[eddress.appURLEnum.LAST_MILE_WAREHOUSE_OVERVIEW_URL]">Orders</a>

                </li>
                <li *ngxPermissionsOnly="[eddress.userRolesEnum.LAST_MILE_TEAM]" routerLinkActive="active">
                  <a [routerLink]="[eddress.appURLEnum.LAST_MILE_TEAMS_OVERVIEW_URL]">Teams</a>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown" [hidden]="!showBillingSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
                                    <i class="ti-receipt"></i>
                                </span>
                <span class="title">Billing</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active">
                  <a *ngxPermissionsOnly="[eddress.userRolesEnum.CUSTOMER_BILLING]" [routerLink]="[eddress.appURLEnum.BILLING_CUSTOMER_URL]">Customer</a>
                </li>
                <li *ngxPermissionsOnly="[eddress.userRolesEnum.AGENT_BILLING]" routerLinkActive="active">
                  <a [routerLink]="[eddress.appURLEnum.BILLING_AGENT_URL]">Agent</a>
                </li>
              </ul>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.CLIENT_VIEW_ORDER]">
              <a href="" [routerLink]="[eddress.appURLEnum.CLIENT_VIEW_DASHBOARD_URL]" class="mrg-top-20">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Dashboard</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.CLIENT_VIEW_PROJECT]">
              <a href="" [routerLink]="[eddress.appURLEnum.CLIENT_VIEW_PROJECTS_URL]">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">Projects</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.CLIENT_VIEW_CUSTOMER]">
              <a href="" [routerLink]="[eddress.appURLEnum.CLIENT_VIEW_CUSTOMERS_URL]">
                <span class="icon-holder"><i class="ti-user"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.INTERIM]">
              <a href="" [routerLink]="[eddress.appURLEnum.INTERIM_ORDERS_URL]">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Interim Order</span>
              </a>
            </li>
            <li class="nav-item dropdown" [hidden]="!showOrderSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder"><i class="fa fa-shopping-cart"></i></span>
                <span class="title">Orders</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.ORDER_LIST]">
                  <a href="" [routerLink]="[ eddress.appURLEnum.MARKETPLACE_ORDERS_URL]">
                    <span class="title">List</span>
                  </a>
                </li>
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.ORDER_LIST]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_ORDER_MAP_URL]">
                    <span class="title">Map</span>
                  </a>
                </li>
              </ul>
            </li>
<!--            <li clas="nav-item dropdown" *ngxPermissionsOnly="['ORDER_LIST']">-->
<!--              <a class="dropdown-toggle" href="javascript:void(0);">-->
<!--                <span class="icon-holder"><i class="fa fa-shopping-cart"></i></span>-->
<!--                <span class="title">Orders</span>-->

<!--              </a>-->

<!--            </li>-->
<!--            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['CUSTOMER_LIST']">-->
<!--              <a href="" [routerLink]="['/marketplace/customers']">-->
<!--                <span class="icon-holder"><i class="ti-user"></i></span>-->
<!--                <span class="title">Customers</span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="nav-item dropdown" [hidden]="!showSettingsSection">-->
<!--              <a class="dropdown-toggle" href="javascript:void(0);">-->
<!--                                <span class="icon-holder">-->
<!--                                  <i class="ti-user"></i>-->
<!--                                </span>-->
<!--                <span class="title">Customers</span>-->
<!--                <span class="arrow">-->
<!--                                    <i class="ti-angle-right"></i>-->
<!--                                </span>-->
<!--              </a>-->
<!--              <ul class="dropdown-menu">-->
<!--                <li routerLinkActive="active" *ngxPermissionsOnly="['CUSTOMER_LIST']">-->
<!--                  <a href="" [routerLink]="['/marketplace/customers']">-->
<!--                    <span class="title">All</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li routerLinkActive="active" *ngxPermissionsOnly="['CUSTOMER_LIST']">-->
<!--                  <a href="" [routerLink]="['/marketplace/customer/blacklisted']">-->
<!--                    <span class="title">Blacklisted</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </li>-->

          <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.MERCHANT_VIEW]">
            <a href=""
              [routerLink]="[eddress.appURLEnum.MARKETPLACE_MERCHANTS_URL]">
              <span class="icon-holder"> <i class="fas fa-house-user"></i> </span>
              <span class="title">Merchants</span>
            </a>
          </li>

          <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.HOME_PAGES_VIEW]">
            <a href=""
              [routerLink]="[eddress.appURLEnum.MARKETPLACE_HOME_PAGE_ITEMS_URL]">
              <span class="icon-holder"><i class="fa fa-wrench"></i></span>
              <span class="title">Home Page Items</span>
            </a>
          </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.CUSTOMER_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_CUSTOMERS_URL]">
                <span class="icon-holder"> <i class="ti-user"></i> </span>
                <span class="title">Customers</span>
              </a>
            </li>


            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TEAM_LIST]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_TEAMS_URL]">
                <span class="icon-holder"> <fa-icon [icon]="faBiking"></fa-icon> </span>

                <span class="title">Teams</span>
              </a>
            </li>

            <li class="nav-item dropdown" [hidden]="!showStoreSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
                                  <i class="ti-home"></i>
                                </span>
                <span class="title">Store</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.INVENTORY_LIST]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_INVENTORY_URL]">
                    <!--                    <span class="icon-holder"><i class="fa fa-ticket"></i></span>-->
                    <span class="title">Inventory</span>
                  </a>
                </li>

              </ul>
            </li>

            <li class="nav-item dropdown" [hidden]="!showSupportSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
<!--                                  <i class="fa fa-headphones"></i>-->
                                  <fa-icon [icon]="faHeadset"></fa-icon>
                                </span>
                <span class="title">Support</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TICKET_LIST]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_INCIDENTS_URL]">
                    <!--                    <span class="icon-holder"><i class="fa fa-ticket"></i></span>-->
                    <span class="title">Incidents</span>
                  </a>
                </li>

              </ul>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.RATING_LIST]">
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TAGS_LIST]">
            <li class="nav-item dropdown" [hidden]="!showMarketingSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
                                    <i class="fa fa-bullhorn"></i>
                                </span>
                <span class="title">Marketing</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.MARKETPLACE]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_REFERRALS_OVERVIEW_URL]">
                    <!--                    <span class="icon-holder"><i class="fa fa-bullhorn"></i></span>-->
                    <span class="title">Referrals</span>
                  </a>
                </li>

              </ul>
            </li>
            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.ALERT_LISTING]">
              <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_ALERT_URL]">
                <span class="icon-holder"> <i class="fa fa-exclamation-circle"></i> </span>

                <span class="title">Alerts</span>
              </a>
            </li>

            <li class="nav-item dropdown" [hidden]="!showSettingsSection">
              <a class="dropdown-toggle" href="javascript:void(0);">
                                <span class="icon-holder">
                                  <i class="ti-settings"></i>
                                </span>
                <span class="title">Settings</span>
                <span class="arrow">
                                    <i class="ti-angle-right"></i>
                                </span>
              </a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.USER_LIST]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_USERS_URL]">
                    <span class="title">Users</span>
                  </a>
                </li>
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.ROLE_LIST]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_ROLES_URL]">
                    <span class="title">Roles</span>
                  </a>
                </li>
                <li routerLinkActive="active" *ngxPermissionsOnly="[eddress.userRolesEnum.TICKET_CONFIG_EDIT]">
                  <a href="" [routerLink]="[eddress.appURLEnum.MARKETPLACE_INCIDENT_CONFIG_URL]">
                    <span class="title">Incident Configuration</span>
                  </a>
                </li>
              </ul>
            </li>

            <!--<li clas="nav-item" routerLinkActive="active">-->
            <!--<a href="" [routerLink]="['/admin-settings']">-->
            <!--<span class="icon-holder"><i class="ti-user"></i></span>-->
            <!--<span class="title">Admin Settings</span>-->
            <!--</a>-->
            <!--</li>-->

            <!--<li clas="nav-item" routerLinkActive="active">
              <a href="" class="mrg-top-20" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Dashboard</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['EDDRESS_BOOK', 'MARKETPLACE_API_MERCHANT']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder">
                  <i class="fa fa-shopping-cart"></i></span>
                <span class="title">Incoming Orders</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a href="https://staging.eddress.co/portal#!/dashboard/orders-tasks-view/" class="mrg-top-20">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Orders</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['CLIENT_VIEW_PROJECT']">
              <a href="" [routerLink]="['/clientview/projects']">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">My Orders</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['CLIENT_VIEW_CUSTOMER']">
              <a href="" [routerLink]="['/clientview/customers']">
                <span class="icon-holder"><i class="ti-user"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a href="" [routerLink]="['/dispatcher/teams']">
                <span class="icon-holder"><i class="ti-package"></i></span>
                <span class="title">Dispatcher</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a href="https://staging.eddress.co/portal#!/dashboard/tasks-listing/">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Tasks</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a href="https://staging.eddress.co/portal#!/dashboard/all-workers-tasks-map/">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Maps</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a href="https://staging.eddress.co/portal#!/dashboard/merchants-view/">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a href="" [routerLink]="['https://staging.eddress.co/portal#!/dashboard/eddresses-view/']">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">My Locations</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a href="https://staging.eddress.co/portal#!/dashboard/worker-list/">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Workers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['DISPATCHER']">
              <a href="https://staging.eddress.co/portal#!/dashboard/reports-view/">
                <span class="icon-holder"><i class="ti-pie-chart"></i></span>
                <span class="title">Reports</span>
              </a>
            </li>

            -&#45;&#45;&#45;&#45;

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['CALL_CENTER_ORDERS']">
              <a href="" [routerLink]="['/dashboard/projects']">
                <span class="icon-holder"><i class="ti-package"></i></span>
                <span class="title">Orders</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['CLIENT_VIEW']">
              <a href="" [routerLink]="['/dashboard/client-orders']">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">My Orders</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['WORKFORCE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">Sales Dashboard</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['WORKFORCE_CALL_CENTER']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">Call Center</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active">
              <a href="" [routerLink]="['/dispatcher/teams']">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">Dispatcher</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['CLIENT_VIEW_EXPRESS']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">Orders</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['WORKFORCE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-cube"></i></span>
                <span class="title">Orders</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['COURIER']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-bars"></i></span>
                <span class="title">Tasks</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['MARKETPLACE', 'MARKETPLACE_API']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-bars"></i></span>
                <span class="title">Orders</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['COURIER', 'WORKFORCE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-globe"></i></span>
                <span class="title">Map</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['COURIER']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-user"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['MARKETPLACE_API']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-user"></i></span>
                <span class="title">Merchants</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['MARKETPLACE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-user"></i></span>
                <span class="title">Merchants</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['MARKETPLACE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-user"></i></span>
                <span class="title">Home Page Items</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['MARKETPLACE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-user"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['MARKETPLACE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-user"></i></span>
                <span class="title">Promo Codes</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['MARKETPLACE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-user"></i></span>
                <span class="title">Area</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['WORKFORCE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-user"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active"
                *ngxPermissionsOnly="['CALL_CENTER_ORDERS', 'COURIER', 'CLIENT_VIEW', 'WORKFORCE', 'CLIENT_VIEW_EXPRESS']">
              <a href="" [routerLink]="['/dashboard/customers']">
                <span class="icon-holder"><i class="ti-user"></i></span>
                <span class="title">Customers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['COURIER', 'WORKFORCE']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-truck"></i></span>
                <span class="title">Workers</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['MERCHANT']">
              <a href="" [routerLink]="['/dashboard']">
                <span class="icon-holder"><i class="fa fa-cubes"></i></span>
                <span class="title">Products</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active">
              <a href="" [routerLink]="['/dashboard/reports']">
                <span class="icon-holder"><i class="ti-bar-chart-alt"></i></span>
                <span class="title">Reports</span>
              </a>
            </li>

            <li clas="nav-item" routerLinkActive="active">
              <a href="" [routerLink]="['/eddress-book/my-eddresses']">
                <span class="icon-holder"><i class="ti-notepad"></i></span>
                <span class="title">Eddress Book</span>
              </a>
            </li>

            <li class="nav-item dropdown" >
                <a class="dropdown-toggle" href="javascript:void(0);" >
                    <span class="icon-holder">
                        <i class="ti-layout-media-overlay"></i>
                    </span>
                    <span class="title">Dropdown</span>
                    <span class="arrow">
                        <i class="ti-angle-right"></i>
                    </span>
                </a>
                <ul class="dropdown-menu">
                    <li>
                        <a href="">item 1</a>
                    </li>
                    <li>
                        <a href="">item 2</a>
                    </li>
                </ul>
            </li>
            <li class="nav-item dropdown">
                <a class="dropdown-toggle" href="javascript:void(0);">
                    <span class="icon-holder">
                        <i class="ti-view-list-alt"></i>
                    </span>
                    <span class="title">Multi Level</span>
                    <span class="arrow">
                        <i class="ti-angle-right"></i>
                    </span>
                </a>
                <ul class="dropdown-menu">
                    <li class="nav-item dropdown">
                        <a href="javascript:void(0);">
                            <span>Level 1.2</span>
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a href="javascript:void(0);">
                            <span>Level 1.1</span>
                            <span class="arrow">
                                <i class="ti-angle-right"></i>
                            </span>
                        </a>
                        <ul class="dropdown-menu">
                            <li>
                                <a href="javascript:void(0);">Level 2</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>-->
            <!--Rawad added Menus-->
            <!--<li class="nav-item dropdown">-->
            <!--<a class="dropdown-toggle" href="javascript:void(0);">-->
            <!--<span class="icon-holder">-->
            <!--<i class="ti-receipt"></i>-->
            <!--</span>-->
            <!--<span class="title">Billing</span>-->
            <!--<span class="arrow">-->
            <!--<i class="ti-angle-right"></i>-->
            <!--</span>-->
            <!--</a>-->
            <!--<ul class="dropdown-menu">-->
            <!--<li class="nav-item dropdown">-->
            <!--<a href="http://localhost:4200/#/dashboard/create-invoices">-->
            <!--<span>Customers</span>-->
            <!--</a>-->
            <!--</li>-->
            <!--<li class="nav-item dropdown">-->
            <!--<a href="http://localhost:4200/#/dashboard/courier-agent-settlements">-->
            <!--<span>Agents</span>-->
            <!--</a>-->
            <!--</li>-->

            <!--</ul>-->
            <!--</li>-->
            <!--<li class="nav-item dropdown">-->
            <!--<a class="dropdown-toggle" href="javascript:void(0);">-->
            <!--<span class="icon-holder">-->
            <!--<i class="ei-truck"></i>-->
            <!--</span>-->
            <!--<span class="title">Last Mile</span>-->
            <!--<span class="arrow">-->
            <!--<i class="ti-angle-right"></i>-->
            <!--</span>-->
            <!--</a>-->
            <!--<ul class="dropdown-menu">-->
            <!--<li class="nav-item dropdown">-->
            <!--<a href="http://localhost:4200/#/dashboard/create-invoices">-->
            <!--<span>Orders</span>-->
            <!--</a>-->
            <!--</li>-->
            <!--<li class="nav-item dropdown">-->
            <!--<a href="http://localhost:4200/#/dashboard/courier-agent-settlements">-->
            <!--<span>Agents</span>-->
            <!--</a>-->
            <!--</li>-->

            <!--</ul>-->
            <!--</li>-->

            <!--Rawad added Menus-->
          </ul>
        </div>
      </div>
      <!-- Side Nav END -->

      <!-- Page Container START -->
      <div class="page-container">

        <!-- Top Nav START -->
        <div class="header navbar">
          <div class="header-container flex-layout-mobile">
            <ul class="nav-left flex-layout-mobile">
              <li>
                <a *ngIf="app.layout.isMenuCollapsed" class="side-nav-toggle" href="javascript:void(0);"
                   (click)="getCollapsed(false)">
                  <i class="ti-view-grid"></i>
                </a>
                <a *ngIf="!app.layout.isMenuCollapsed" class="side-nav-toggle" href="javascript:void(0);"
                   (click)="getCollapsed(true)">
                  <i class="ti-view-grid"></i>
                </a>
              </li>
              <li class="white-space-nowrap">
                <span class="side-nav-toggle" style="line-height: 58px;">
                  <i class="page-title">{{pageTitle}}</i>
                </span>
              </li>
              <li>
                <!--<a class="btn btn-default btn-sm text-info no-background mrg-left-5 mrg-top-5">Back</a>-->
                <button [hidden]="!isBackBtn"
                        class="btn btn-info btn-inverse btn-rounded btn-sm text-info mrg-top-13 mrg-left-10 no-background-custom"
                        type="button" (click)="back()">
                  Back
                </button>
              </li>
            </ul>
            <ul class="nav-right flex-layout-mobile-right">
              <li class="mrg-top-4" [hidden]="!isMainPage" *ngxPermissionsOnly="[eddress.userRolesEnum.DISPATCHER, eddress.userRolesEnum.INTERIM]">
                <button class="btn btn-info btn-inverse btn-rounded btn-sm text-info mrg-top-13 no-background-custom"
                        type="button" (click)="mainPortal()">
                  Main Portal
                </button>
              </li>

              <!--<li><div class="form-group mrg-top-10">-->
              <!--<div class="input-icon  form-group">-->
              <!--<i class="ti-search" style="top: 8px"></i>-->
              <!--<input type="text" class="form-control input-sm mrg-top-20" placeholder="Search">-->
              <!--</div>-->
              <!--</div></li>-->
              <li [hidden]="true  " *ngxPermissionsExcept="[eddress.userRolesEnum.MARKETPLACE]">
                <div class="form-group mrg-top-10">
                  <div class="input-icon  form-group mrg-left-10">
                    <i class="ti-search" style="top: 8px"></i>
                    <input type="text" class="form-control input-sm mrg-top-20" placeholder="Search">
                  </div>
                </div>
              </li>
              <li class="user-profile dropdown">
                <a class="dropdown-toggle mrg-right-15" data-toggle="dropdown" href="" aria-expanded="false">
                  <!--<img class="profile-img img-fluid" [src]="companyLogo">-->
                  <div class="user-info">
                    <span class="name pdd-right-5 hide-company-name">{{userData?.userName}}</span>
                    <i class="ti-angle-down font-size-10"></i>
                  </div>
                </a>
                <ul class="dropdown-menu">
                  <!--<li>
                    <a href="">
                      <i class="ti-settings pdd-right-10"></i>
                      <span>Setting</span>
                    </a>
                  </li>-->
                  <!--                  <li>-->
                  <!--                    <a href="" [routerLink]="['/dashboard/profile']">-->
                  <!--                      <i class="ti-user pdd-right-10"></i>-->
                  <!--                      <span>Profile</span>-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                  <!--<li>-->
                  <!--<a href="">-->
                  <!--<i class="ti-email pdd-right-10"></i>-->
                  <!--<span>Inbox</span>-->
                  <!--</a>-->
                  <!--</li>-->
                  <!--                  <li class="divider" role="separator"></li>-->
                  <li *ngxPermissionsOnly="[eddress.userRolesEnum.DISPATCHER]">
                    <a href="" [routerLink]="[eddress.appURLEnum.ADMIN_SETTINGS]">
                  <span class="icon-holder">
                    <i class="ti-settings pdd-right-10"></i></span>
                      <span class="title">Setting</span>
                    </a>
                  </li>
                  <li>
                    <a *ngIf="(isLoggedIn | async)" (click)="logout()">
                      <i class="ti-power-off pdd-right-10"></i>
                      <span class="pointer">Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
              <!--<li class="notifications dropdown">
                <span class="counter">2</span>
                <a class="dropdown-toggle" data-toggle="dropdown" href="">
                  <i class="ti-bell"></i>
                </a>
                <ul class="dropdown-menu ">
                  <li class="notice-header">
                    <i class="ti-bell pdd-right-10"></i>
                    <span>Notifications</span>
                  </li>
                  <ul class="list-info overflow-y-auto relative scrollable ps ps&#45;&#45;active-y">
                    <li>
                      <a href="">
                        <img alt="" class="thumb-img" src="assets/images/avatars/thumb-5.jpg">
                        <div class="info">
                          <span class="title">
                            <span class="font-size-14 text-semibold">Jennifer Watkins</span>
                            <span class="text-gray">commented on your <span class="text-dark">post</span></span></span>
                          <span class="sub-title">5 mins ago</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img alt="" class="thumb-img" src="assets/images/avatars/thumb-4.jpg">
                        <div class="info">
                          <span class="title">
                            <span class="font-size-14 text-semibold">Samuel Field</span>
                            <span class="text-gray">likes your <span class="text-dark">photo</span></span></span>
                          <span class="sub-title">8 hours ago</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span class="thumb-img bg-primary">
                          <span class="text-white">M</span></span>
                        <div class="info"><span class="title">
                          <span class="font-size-14 text-semibold">Michael Birch</span>
                          <span class="text-gray">likes your <span class="text-dark">photo</span></span></span>
                          <span class="sub-title">5 hours ago</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span class="thumb-img bg-success"><span class="text-white"><i class="fa fa-paper-plane-o"></i></span>
                        </span>
                        <div class="info">
                          <span class="title">
                            <span class="font-size-14 text-semibold">Message sent</span></span>
                          <span class="sub-title">8 hours ago</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span class="thumb-img bg-info">
                          <span class="text-white"><i class="ti-user"></i></span>
                        </span>
                        <div class="info">
                          <span class="title">
                            <span class="font-size-14 text-semibold">Admin</span>
                            <span class="text-gray">Welcome on board</span>
                          </span>
                          <span class="sub-title">8 hours ago</span>
                        </div>
                      </a>
                    </li>
                    <div class="ps__rail-x" style="left: 0px; top: 0px;">
                      <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                    </div>
                    <div class="ps__rail-y" style="top: 0px; height: 248px; left: 0px;">
                      <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 149px;"></div>
                    </div>
                  </ul>
                  <li class="notice-footer">
                    <span>
                      <a class="text-gray" href="">Check all notifications <i class="ei-right-chevron pdd-left-5 font-size-10"></i></a>
                    </span>
                  </li>
                </ul>
              </li>-->
              <!-- <li>
                <a class="side-panel-toggle" href="javascript:void(0);">
                  <i class="ti-align-right"></i>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
        <!-- Top Nav END -->

        <!-- Side Panel START -->
        <div class="side-panel" [ngClass]="{'side-panel-open' : app.layout.sidePanelOpen}">
          <div class="side-panel-wrapper bg-white">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item active">
                <a class="nav-link" href="#tab1" role="tab" data-toggle="tab">
                  <span>Tab 1</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#tab2" role="tab" data-toggle="tab">
                  <span>Tab 2</span>
                </a>
              </li>
              <li class="nav-item ">
                <a class="nav-link" href="#tab3" role="tab" data-toggle="tab">
                  <span>Tab 3</span>
                </a>
              </li>
              <li class="panel-close">
                <a class="side-panel-toggle" href="javascript:void(0);"
                   (click)="app.layout.sidePanelOpen = !app.layout.sidePanelOpen">
                  <i class="ti-close"></i>
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="tab1" role="tabpanel" class="tab-pane fade in active">
              </div>
              <div id="tab2" role="tabpanel" class="tab-pane fade">
              </div>
              <div id="tab3" role="tabpanel" class="tab-pane fade">
              </div>
            </div>
          </div>
        </div>
        <!-- Side Panel END -->

        <!-- Content Wrapper START -->
        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
        <!-- Content Wrapper END -->

        <!-- Footer START -->
        <!--<footer class="content-footer">-->
        <!--<div class="footer">-->
        <!--<div class="copyright">-->
        <!--<span>Copyright © 2017</span>-->
        <!--<span class="go-right">-->
        <!--<a href="" class="text-gray mrg-right-15">Link 1</a>-->
        <!--<a  href="" class="text-gray">Link 2</a>-->
        <!--</span>-->
        <!--</div>-->
        <!--</div>-->
        <!--</footer>-->
        <!-- Footer END -->

      </div>
      <!-- Page Container END -->

    </div>
  </div>
</div>
