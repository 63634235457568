import { Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { UtilityService } from './utility.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UIStateService } from './uiState.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/internal/operators';
import { ApiService } from './api.service';
import { NotificationService } from './notification.service';
import { Title } from '@angular/platform-browser';
import { ReactiveFormService } from '@core/services/reactiveForm.service';
import { AppRoutesEnum } from '@core/models/appRoutes.enum';
import { UserRolesEnum } from '@core/models/userRoles.enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "@angular/platform-browser";
var EddressFacadeService = /** @class */ (function () {
    function EddressFacadeService(_document, injector, router, titleService) {
        this._document = _document;
        this.injector = injector;
        this.router = router;
        this.titleService = titleService;
        this.appURLEnum = AppRoutesEnum;
        this.userRolesEnum = UserRolesEnum;
    }
    Object.defineProperty(EddressFacadeService.prototype, "authService", {
        get: function () {
            if (!this._authService) {
                this._authService = this.injector.get(AuthService);
            }
            return this._authService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EddressFacadeService.prototype, "api", {
        get: function () {
            if (!this._api) {
                this._api = this.injector.get(ApiService);
            }
            return this._api;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EddressFacadeService.prototype, "utilityService", {
        get: function () {
            if (!this._utilityService) {
                this._utilityService = this.injector.get(UtilityService);
            }
            return this._utilityService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EddressFacadeService.prototype, "uiStateService", {
        get: function () {
            if (!this._uiStateService) {
                this._uiStateService = this.injector.get(UIStateService);
            }
            return this._uiStateService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EddressFacadeService.prototype, "reactiveFormService", {
        get: function () {
            if (!this._reactiveFormService) {
                this._reactiveFormService = this.injector.get(ReactiveFormService);
            }
            return this._reactiveFormService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EddressFacadeService.prototype, "modalService", {
        get: function () {
            if (!this._modalService) {
                this._modalService = this.injector.get(NgbModal);
            }
            return this._modalService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EddressFacadeService.prototype, "notify", {
        get: function () {
            if (!this._notify) {
                this._notify = this.injector.get(NotificationService);
            }
            return this._notify;
        },
        enumerable: true,
        configurable: true
    });
    EddressFacadeService.prototype.setDescription = function (description) {
        this.description = description;
    };
    EddressFacadeService.prototype.setCompanyLogo = function (logo) {
        this.companyLogo = logo;
    };
    EddressFacadeService.prototype.setLogoIcon = function (logo) {
        this.logoIcon = logo;
    };
    EddressFacadeService.prototype.setBackgroundImage = function (image) {
        this.backgroundImage = image;
    };
    EddressFacadeService.prototype.setBackgroundColor = function (color) {
        this.backgroundColor = color;
    };
    EddressFacadeService.prototype.setAppFavicon = function (icon) {
        this._document.getElementById('appFavicon').setAttribute('href', icon);
    };
    EddressFacadeService.prototype.getDescription = function () {
        return this.description;
    };
    EddressFacadeService.prototype.getCompanyLogo = function () {
        if (!this.companyLogo) {
            this.companyLogo = localStorage.getItem('companyLogo');
        }
        return this.companyLogo;
    };
    EddressFacadeService.prototype.getLogoIcon = function () {
        return this.logoIcon;
    };
    EddressFacadeService.prototype.getBackgroundImage = function () {
        return this.backgroundImage;
    };
    EddressFacadeService.prototype.getBackgroundColor = function () {
        return this.backgroundColor;
    };
    EddressFacadeService.prototype.getCurrentUser = function () {
        return this.authService.currentUser;
    };
    EddressFacadeService.prototype.getIsMenuCollapsed = function () {
        return this.uiStateService.isMenuCollapsed;
    };
    EddressFacadeService.prototype.attemptAuth = function (username, password) {
        return this.authService.attemptAuth(username, password);
    };
    EddressFacadeService.prototype.companyConfiq = function (companyName) {
        return this.api
            .get('portal/fieldforce/common/companyInfo/' + companyName)
            .pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.forgotPassword = function (username) {
        return this.api
            .post('portal/common/user/forgotPassword', { userName: username })
            .pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.verifyUser = function (token) {
        return this.api.get('portal/common/user/register/' + token).pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.changePassword = function (token, password) {
        return this.api
            .post('portal/common/user/changePassword', {
            token: token,
            password: password
        })
            .pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.populateUser = function () {
        return this.authService.populateUser();
    };
    EddressFacadeService.prototype.getCities = function () {
        return this.api.get('v1/area/cities').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getCitiesFromMongo = function () {
        return this.api
            .get('portal/common/constants/cities')
            .pipe(map(function (data) { return data; }));
    };
    EddressFacadeService.prototype.setCompanyInfo = function (data) {
        var companyIfo = data.companyInfo;
        this.setDescription(companyIfo.description);
        this.setCompanyLogo(companyIfo.companyLogo);
        this.setLogoIcon(companyIfo.logoIcon);
        this.setBackgroundImage(companyIfo.backgroundImage);
        this.setBackgroundColor(companyIfo.backgroundColor);
        if (companyIfo.favIcon) {
            this.setAppFavicon(companyIfo.favIcon);
            localStorage.setItem('favIcon', companyIfo.favIcon);
        }
        if (companyIfo.title) {
            this.titleService.setTitle(companyIfo.title);
            localStorage.setItem('title', companyIfo.title);
        }
        localStorage.setItem('companyLogo', companyIfo.companyLogo);
        localStorage.setItem('logoIcon', companyIfo.logoIcon);
    };
    EddressFacadeService.prototype.errorNotification = function (warning) {
        return this.notify.errorNotification(warning);
    };
    EddressFacadeService.prototype.successNotification = function (warning) {
        return this.notify.successNotification(warning);
    };
    EddressFacadeService.prototype.infoNotification = function (warning) {
        return this.notify.infoNotification(warning);
    };
    EddressFacadeService.prototype.warningNotification = function (warning) {
        return this.notify.warningNotification(warning);
    };
    EddressFacadeService.prototype.waitNotification = function (warning) {
        return this.notify.waitNotification(warning);
    };
    EddressFacadeService.prototype.getWorkers = function () {
        return this.api.get('portal/fieldforce/common/workers').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getCourierWorkers = function () {
        var params = {
            'workerList': true
        };
        return this.api.get('v1/workers', params).pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getCustomers = function () {
        return this.api.get('portal/fieldforce/common/customers').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getTeams = function () {
        return this.api.get('portal/fieldforce/common/team').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getZones = function () {
        return this.api.get('portal/fieldforce/common/zoneTurf').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getWarehoues = function () {
        return this.api.get('portal/fieldforce/common/warehouses').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getVehicleTypes = function () {
        return this.api
            .get('portal/clientView/thirdPartyVehicleTypes')
            .pipe(map(function (data) { return data; }));
    };
    EddressFacadeService.prototype.cancelOrders = function (params) {
        return this.api
            .put('portal/fieldforce/lastmile/orders/cancelOrders', params)
            .pipe(map(function (data) { return data; }));
    };
    EddressFacadeService.prototype.getCancelReasons = function () {
        return this.api
            .get('market/orders/cancellationReasons')
            .pipe(map(function (data) { return data; }));
    };
    EddressFacadeService.prototype.getStores = function () {
        return this.api.get('market/orders/stores').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getCountries = function () {
        return this.api.get('portal/common/constants/countries').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getCategories = function (companyUid) {
        return this.api
            .get('market/products/categories?thirdPartyUid=' + companyUid)
            .pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getAllMarketCollections = function () {
        return this.api.get('market/admin/collections/all').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getUsersConfigurations = function () {
        return this.api.get('v1/marketplace/configurations').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getAllCities = function () {
        return this.api
            .get('v1/area/cities')
            .pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.prototype.getSettingCountries = function () {
        return this.api.get('v1/settings/getCountries').pipe(map(function (data) {
            return data;
        }));
    };
    EddressFacadeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EddressFacadeService_Factory() { return new EddressFacadeService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Title)); }, token: EddressFacadeService, providedIn: "root" });
    return EddressFacadeService;
}());
export { EddressFacadeService };
