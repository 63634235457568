import { InjectionToken } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var apiBaseUrl = new InjectionToken('apiBaseUrl');
var ApiService = /** @class */ (function () {
    function ApiService(http, _apiBaseUrl) {
        this.http = http;
        this._apiBaseUrl = _apiBaseUrl;
    }
    ApiService.prototype.get = function (path, params) {
        if (params === void 0) { params = new HttpParams(); }
        return this.http.get("" + this._apiBaseUrl + path, { params: params });
    };
    ApiService.prototype.put = function (path, body) {
        if (body === void 0) { body = {}; }
        return this.http.put("" + this._apiBaseUrl + path, body);
    };
    ApiService.prototype.patch = function (path, body) {
        if (body === void 0) { body = {}; }
        return this.http.patch("" + this._apiBaseUrl + path, body);
    };
    ApiService.prototype.post = function (path, body) {
        if (body === void 0) { body = {}; }
        return this.http.post("" + this._apiBaseUrl + path, body);
    };
    ApiService.prototype.delete = function (path) {
        return this.http.delete("" + this._apiBaseUrl + path);
    };
    ApiService.prototype.bulkPost = function (path, body, header) {
        if (body === void 0) { body = {}; }
        if (header === void 0) { header = {}; }
        return this.http.post("" + this._apiBaseUrl + path, body, header);
    };
    ApiService.prototype.bulkPut = function (path, body, header) {
        if (body === void 0) { body = {}; }
        if (header === void 0) { header = {}; }
        return this.http.put("" + this._apiBaseUrl + path, body, header);
    };
    ApiService.prototype.getBlob = function (path, params) {
        if (params === void 0) { params = new HttpParams(); }
        return this.http.get("" + this._apiBaseUrl + path, {
            responseType: 'blob'
        });
    };
    ApiService.prototype.getBlobPost = function (path, body) {
        if (body === void 0) { body = {}; }
        return this.http.post("" + this._apiBaseUrl + path, body, {
            observe: 'response',
            responseType: 'blob'
        });
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(apiBaseUrl)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
