import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Layout Modules
import {
	CommonLayoutComponent,
	mainPortalUrl
} from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
// Directives
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Routing Module
import { AppRoutes } from './app.routing';
// App Component
import { AppComponent } from './app.component';
// Core module for whole application act like singleton
import { CoreModule } from '@core/core.module';
// Related to app url
import { apiBaseUrl } from '@core/services/api.service';
import { graphqlBaseUrl } from '@core/services/graphql.service';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { environment } from '@environments/environment';
import { PageNotFoundComponent } from '@app/common/page-not-found.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	imports: [
		BrowserModule,
		CoreModule,
		RouterModule.forRoot(AppRoutes, { useHash: true }),
		NgbModule.forRoot(),
		LoadingBarModule,
		BrowserAnimationsModule,
		FontAwesomeModule
		/*ServiceWorkerModule.register('./ngsw-worker.js', {
			enabled: environment.production
		})*/
	],
	declarations: [
		AppComponent,
		CommonLayoutComponent,
		AuthenticationLayoutComponent,
		PageNotFoundComponent
	],
	providers: [
		{ provide: apiBaseUrl, useValue: environment.eddressAppConfig.apiBaseUrl },
		{
			provide: graphqlBaseUrl,
			useValue: environment.eddressAppConfig.graphqlBaseUrl
		},
		{
			provide: mainPortalUrl,
			useValue: environment.eddressAppConfig.mainPortalUrl
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
